@import "../../mixins/style.mixins";


// ------------------------------------------------------
.csw-header {
    color: #fff;
    background-color: #385F71;
    z-index: 5;
    @extend %solid_bg;
}

.csw-header_custom-dev {
	background-image: url("img/bg_header_custom-dev.jpg");
}

.csw-header_tech-audit {
	background-image: url("img/bg_header_tech-audit.jpg");
}

.csw-header_home {
	background-image: url("img/bg_header_home.jpg");
}

.csw-header_services{
	background-image: url("img/bg-header-services.jpg");
}

.csw-header_industries{
	background-image: url("img/bg-header_industries.jpg");
}

.csw-header_integration{
	background-image: url("img/bg-header_integration.jpg");
}

.csw-header_industries-man{
	background-image: url("img/header-bg_manufacturing.jpg");
}

.csw-header_industries-fin{
	background-image: url("img/header-bg_finance.jpg");
}

.csw-header_industries-ret{
	background-image: url("img/header-bg_retail.jpg");
}

.csw-header_industries-trans{
	background-image: url("img/header-bg_transporation.jpg");
}

.csw-header_industries-health{
	background-image: url("img/header-bg_industries-healthcare.jpg");
}

.csw-header_industries-non-profits{
	background-image: url("img/header-bg_non-profits.jpg");
}

.csw-header_about{
	background-image: url("img/header-bg_about.jpg");
}

.csw-header_lightning {
	background-image: url("img/bg-header_lightning.jpg");
}

.csw-header_appexchange {
	background-image: url("img/bg-header_appexchange.jpg");
}

.csw-header_implementation {
	background-image: url("img/bg-header_impl.jpg");
}

.csw-header_case-study{
	background-image: url("img/bg-header_case-study.jpg");
}

.csw-header_hospitality{
	background-image: url("img/bg-header_hospitality.jpg");
}


