@import "../../../mixins/style.mixins";


.csw-link {
    color: inherit;
    display: inline-block;
    text-decoration: none;
    &:hover {
    	color: inherit;
        text-decoration: underline;
    }
    &.decorated {
        text-decoration: underline;
    }

    &.no-underline{
        text-decoration: none;
    }
}

// --------------------------------------------------------------
.csw-link_nav {
    font-size: 18px;
    font-size: 1.8rem;
    // line-height: 28px;
    color:#fff;
    padding: 10px 20px;

    &:hover{
        color:#fff;
    }

    &.active{
        background-color: $green;
    }
}

// --------------------------------------------------------------
.green_blue .csw-txt.large .csw-link {
    margin-right: 5px;
}

.csw-link_case-stydy{
    display: inline-block;
    max-width: 100%;
    &:focus{outline: none;}
}

.csw-link_person-email{
    color: #347bc5;
    font-size: 16px;
    font-size: 1.6rem;
}

.csw-link_learn-more-implement{
    color: #fff;
    text-decoration: none;
    transition: all .2s ease-out;
    &:hover{
        text-decoration: inherit;
        font-size: 1.8rem;
    }
    @media(max-width: 800px){
        color: #29abc6;
        &:hover{
            text-decoration: inherit;
            font-size: 1.8rem;
            color: darken(#29abc6, 15%);
        }
    }
}





