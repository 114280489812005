@import "../../mixins/style.mixins";
// @import "../../mixins/style.placeholder";


// html,
// body {
//     height: 100%;
// }
html{

    font-size: 10px;
    line-height: 1.6;
    @media(max-width:500px){
        font-size: 8px;
    }

    & * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

body {
    font-family: $font_rg;
    color: #111;
    padding: 0;
    margin: 0;
    font-size: 1.7rem;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
}