@import "../../../mixins/style.mixins";


// -------------------------------------------------------------------
.csw-table {
    margin: 0;
    &__container {
        display: table;
        width: 100%;
    }
    &__head {
        display: table-header-group;
    }
    &__body {
        display: table-row-group;
    }
    &__foot {
        display: table-footer-group;
    }
    &__row {
        display: table-row;
    }
    &__col {
        display: table-cell;
    }
}

// -------------------------------------------------------------------
.csw-table_header {
    @extend %table_middle;
    padding: 170px 0 100px;
    margin: 0 auto;
    @media(max-width:970px){
        padding: 100px 0;
    }
    @media(max-width:670px){
        padding: 80px 0;
    }
    @media(max-width:450px){
        padding: 50px 0;
    }
}

// -------------------------------------------------------------------
.csw-table_case-stydy {
    @extend %table_middle;
    .csw-table__container{
        @media(max-width: 600px){
            
        }
    }
    .csw-table__col {
        width: 50%;
        padding: 20px;
        @media(max-width: 600px){
            width: 100%;
            height: 200px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        @extend %solid_bg;
        &:first-child {
            * {line-height: 1.4;}
            color: #fff;
        }
    }
}












