@import "../../../mixins/style.mixins";
$slick-disabled-color: #ddd;

.csw-carousel{
    
}

.slick-slide{
    &:focus{
        outline: none;
    }
}
.slick-dots{
        position: relative;
        bottom: 0;
        text-align: center;
        list-style: none;
        padding: 0;
        li{
            display: inline-block;
            margin: 0 5px;
            button{
                padding: 0;
                outline: none;
                font-size: 0;
                line-height: 0;
                height: 18px;
                width: 18px;
                border:0;
                border-radius:50%;
                background-color: rgb(223, 226, 237);
            }
            &.slick-active{
                button{
                    background-color: rgb(107, 116, 145);
                }
            }
            button:hover{
                    background-color: rgb(107, 116, 145);
            }
        }
    }
    
.csw-carousel_case-stydy {
    
    position:relative;
    .slick-arrow {
        outline: none;
        font-size: 0;
        border: none;
        position: absolute;
        width: 60px;
        bottom: 0;
        top: 0;
        z-index: 100;
        background-color: transparent;
        &:after{
            font-size: 50px;
            font-family: 'fontello';
            color: #999;
        }
        &:hover:after{
            color: #222;
        }
    }
    .slick-next {
        right: 0px;
        @extend .slick-arrow;
        &:after{
            content:'\E80c';
        }
    }
    .slick-prev {
        left: 0px;
        @extend .slick-arrow;
        &:after{
            content:'\E80b';
        }
    }
    .slick-disabled {
        &:after{
            color: $slick-disabled-color;
        }
        &:hover:after{
            color: $slick-disabled-color;
        }
    }
}

.slick-dots_case-studies{
    margin-top: 20px;
}

.csw-carousel__buttons{
    overflow: hidden;
    padding: 1px;
    .slick-prev, .slick-next{
        user-select: none;
        border: 0;
        font-family: 'fontello';
        font-size: 25px;
        font-size: 2.5rem;
        line-height: 1;
        color: $blue;
        background-color: transparent;
        padding: 5px 0;
        margin-top: 30px;
        border: 1px solid $blue;
        min-width: 60px;
        transition: all .2s linear;
        outline: none;
        &:hover{
            background-color: $blue;
            color: #fff;
        }
        &:active{
            transform: scale(.9);
        }
    }
    .slick-disabled{
        $disabledColor: #6A6A6A;
        color: $disabledColor;
        border-color: $disabledColor;
        &:hover{
            background-color: inherit;
            color: $disabledColor;
        }
        &:active{
            transform: none;
        }
    }
    .slick-prev{
        float: left;
    }
    .slick-next{
        float: right;
    }
}

.csw-carousel__buttons-testimonial{
    padding-right: 300px;
    @media(max-width: 900px){
        padding-right: 0;
    }
}

.csw-carousel__buttons-team{
    .slick-prev{
        margin-left: 40px;
    }
    .slick-next{
        margin-right: 40px;
    }
    
    @media(max-width: 750px){
        max-width: 339px;
        margin: 0 auto;
    }
}