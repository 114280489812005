@import "./mixins/style.placeholder";


/* ================================================================
    COMMON
================================================================ */
//@import "common/animate/style.animate";
//@import "common/animate/style.user-animate";
@import "_common/twtrbtstp/style.twtrbtstp-fix";
@import "_common/reset/style.reset";


/* ================================================================
    LAYOUT
================================================================ */
// @import "layout/style.layout";
@import "layout/nav/style.nav";
@import "layout/header/style.header";
// @import "layout/main/style.main";
// @import "layout/footer/style.footer";
@import "layout/wrapper/style.wrapper";


/* ================================================================
    COMPONENTS
================================================================ */
// BUILTIN:
// ------------------------
// @import "components/builtin/article/style.article";
// @import "components/builtin/aside/style.aside";
// @import "components/builtin/badge/style.badge";
@import "components/builtin/btn/style.btn";
// @import "components/builtin/content/style.content";
// @import "components/builtin/dropdown/style.dropdown";
@import "components/builtin/form/style.form";
@import "components/builtin/icon/style.icon";
@import "components/builtin/image/style.image";
@import "components/builtin/input/style.input";
@import "components/builtin/item/style.item";
@import "components/builtin/link/style.link";
@import "components/builtin/list/style.list";
@import "components/builtin/paragraph/style.paragraph";
@import "components/builtin/subscribe/style.subscribe";
// @import "components/builtin/picture/style.picture";
@import "components/builtin/section/style.section";
@import "components/builtin/grid/style.grid";
@import "components/builtin/title/style.title";
@import "components/builtin/table/style.table";
@import "components/builtin/nav/style.nav";
// @import "components/builtin/toggle/style.toggle";
@import "components/builtin/txt/style.txt";
// ------------------------
// CUSTOM:
// ------------------------
@import "components/custom/carousel/style.carousel";
@import "components/custom/telescope/style.telescope";
// @import "components/custom/logo/style.logo";


/* ================================================================
    FONTFACE
================================================================ */
@import "_common/fontface/font-awesome";
@import "_common/fontface/style.fontface";

.grecaptcha-badge{
    display: none;
}






