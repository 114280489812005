@import "../../../mixins/style.mixins";

.csw-subscribe{
	text-align: center;
	position: fixed;
	max-width: 380px;
	left: -380px;
	top: 50%;
	transform: translateY(-50%);
	box-shadow: 2px 2px 20px rgba(0,0,0,.3);
	overflow: hidden;
	background-color: #fff;
	z-index: 999999;
	// padding: 50px 20px 20px;

	.csw-subscribe__container{
		padding: 30px 0;
		max-width: 80%;
		margin: 0 auto;
	}

	.csw-subscribe__close{
		font-family: fontello;
		color: #fff;
		font-weight: bold;
		position: absolute;
		top: 0;
		right: 0;
		padding: 10px;
		line-height: 1;
		cursor: pointer;
		transition: all .1s ease-out;
		&:hover{
			padding: 12px;
			font-size: 19px;
		}
		&:before{
			content: "";
			display: block;
			position: absolute;
			background-color: $green;
			width: 170%;
			height: 170%;
			top: 0;
			right: 0;
			z-index: -1;
			border-radius: 0 0 0 70%;
			margin-top: -50%;
			margin-right: -50%;
		}
	}

	.csw-subscribe__title{
		font-weight: bold;
		text-transform: uppercase;
		color: $blue;
	}

	animation-name: shake;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    &:hover{
    	animation-name: none;
    }
	transition: left .5s ease-out;
    &.active{
    	left: 0;
    }
}

@keyframes shake{
	2%{
		margin-top: -10px;
	}
	4%{
		margin-top: 0;
	}
	6%{
		margin-top: 10px;
	}
	8%{
		margin-top: 0;
	}
}