%shadow_transition {
    transition: box-shadow .15s ease-in;
}

%solid_bg {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}

%shadow_transition_slow {
    transition: box-shadow .3s ease-out;
}

%clearfix {
    zoom:1;
    &:before, &:after {
        content: ""; 
        display: block; 
        height: 0; 
        overflow: hidden; 
    }
    &:after {
        clear: both;
    }
}

%elipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

%fontello {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%table_middle {
    .csw-table__container {
        overflow: hidden;
        height: 100%;
    }
    .csw-table__col {
        text-align: center;
        vertical-align: middle;
        margin: 0 auto;
    }
}

