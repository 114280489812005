// @import "../../mixins/style.mixins";

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=PT+Sans');

// ------------------------------------------------------------
// CUSTOM FONTS / CUSTOM FONTS / CUSTOM FONTS / CUSTOM FONTS /
// ------------------------------------------------------------
@font-face {
    font-family: 'railway';
    src: url('../fonts/railway-webfont.eot');
    src: url('../fonts/railway-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/railway-webfont.woff2') format('woff2'),
         url('../fonts/railway-webfont.woff') format('woff'),
         url('../fonts/railway-webfont.ttf') format('truetype'),
         url('../fonts/railway-webfont.svg#railwayregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-bold-webfont.eot');
    src: url('../fonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-bold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-bold-webfont.woff') format('woff'),
         url('../fonts/opensans-bold-webfont.ttf') format('truetype'),
         url('../fonts/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

// @font-face {
//     font-family: 'pt_sansbold';
//     src: url('../fonts/pt_sans-web-bold-webfont.eot');
//     src: url('../fonts/pt_sans-web-bold-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/pt_sans-web-bold-webfont.woff2') format('woff2'),
//          url('../fonts/pt_sans-web-bold-webfont.woff') format('woff'),
//          url('../fonts/pt_sans-web-bold-webfont.ttf') format('truetype'),
//          url('../fonts/pt_sans-web-bold-webfont.svg#pt_sansbold') format('svg');
//     font-weight: normal;
//     font-style: normal;

// }




@font-face {
    font-family: 'pt_sansregular';
    src: url('../fonts/pt_sans-web-regular-webfont.eot');
    src: url('../fonts/pt_sans-web-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans-web-regular-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans-web-regular-webfont.woff') format('woff'),
         url('../fonts/pt_sans-web-regular-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans-web-regular-webfont.svg#pt_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


// --------------------------------------------------------
// ICONS / ICONS / ICONS / ICONS / ICONS / ICONS / ICONS /
// --------------------------------------------------------
@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?71777979');
  src: url('../fonts/fontello.eot?71777979#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?71777979') format('woff2'),
       url('../fonts/fontello.woff?71777979') format('woff'),
       url('../fonts/fontello.ttf?71777979') format('truetype'),
       url('../fonts/fontello.svg?71777979#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  // width: 1em;
  // margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  // margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-analysis:before { content: '\e821'; } /* '' */
.icon-processing:before { content: '\e822'; } /* '' */
.icon-menu:before { content: '\e802'; } /* '' */
.icon-cancel:before { content: '\e803'; } /* '' */
.icon-implement:before { content: '\e80a'; } /* '' */
.icon-left:before { content: '\e805'; } /* '' */
.icon-right:before { content: '\e806'; } /* '' */
.icon-up:before { content: '\e807'; } /* '' */
.icon-down:before { content: '\e808'; } /* '' */
.icon-workflow:before { content: '\e81d'; } /* '' */
.icon-modules:before { content: '\e820'; } /* '' */
.icon-testing:before { content: '\e81e'; } /* '' */
.icon-handshake:before { content: '\e80c'; } /* '' */
.icon-recommend:before { content: '\e80d'; } /* '' */
.icon-reports:before { content: '\e80e'; } /* '' */





