@import "../../../mixins/style.mixins";


// .csw-section,
// .csw-section__container,
// .csw-section__head,
// .csw-section__body,
// .csw-section__foot {}
// -----------------------------------------------------------------
.csw-section,
.csw-section__container {
	position: relative;
}
.csw-section {
	&.blue,
	&.green,
	&.green_blue,
	&.cs-trust-partner,
	&.cs-feedback,
	&.what-will-you-get {
		color: #fff;
	}
	&.green {
		background-color: $green;
	}
	&.blue{
		background-color:$blue;
	}
	&.ligth-gray {
		background-color: #eff3f7;
	}
	&.silver{
		background-color: #e5e8ef;
	}
}
.csw-section__container {
	padding: 6.5rem 0;
}

.csw-section.first{
	.csw-section__container{
		padding: 4rem 0 6.5rem !important;
	}
}

.csw-section__body {
	margin-top: 30px;
}


// -----------------------------------------------------------------
.csw-section.tech-audit {
	.csw-section__row + .csw-section__row {
		margin-top: 30px;
	}
}

// -----------------------------------------------------------------
.csw-section.green_blue {
	$bdWidth: 400px;
	

	.csw-section__row {
		display: flex;
		flex-direction: row;

		.csw-section__col{
			display: flex;
			flex-direction: column;
			&:first-child{
				justify-content: center;
				width: 40%;
				&.find-out-more{
					padding: 0 200px 0 0;
					@media(max-width:1149px){
						padding: 0 100px 0 0;
					}
				}
				&.own-sf-pdo{
					padding: 0 230px 0 0;
					@media(max-width:1149px){
						padding: 0 100px 0 0;
					}
				}
			}
			&:last-child{
				width: 60%;
				padding: 0;
			}
			@media(max-width:870px){
				width: 100%!important;
			}
		}
		@media(max-width:870px){
			flex-direction: column;
		}
	}

	.csw-section__container {
		overflow: hidden;
		z-index: 1;
		@media(min-width:871px){
			&:before,
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
			}
			@media(min-width:1150px){
				&:before {
					border-color: $green $blue $blue $green;
					border-width: $bdWidth .75*$bdWidth;
					border-style: solid;
					margin-left: -1.4*$bdWidth;
					margin-top: -1*$bdWidth;
					height: 0;
					width: 0;
					left: 50%;
					top: $bdWidth*.5;
					z-index: 1;
				}
			}
			&:after {
				background-color: $blue;
				right: 0;
				left: 50%;
				top: 0;
				width: 50%;
				z-index: 0;
				@media(max-width:1149px){
					left: 35%;
					width: 65%;
				}
			}
		}
	}
}

// -----------------------------------------------------------------
.csw-section.sf-customization-pattern {
	position: relative;
	overflow: hidden;
	.csw-section_path-hider{
		display: none;
		$width: 200px;
		position: absolute;
		height: 100%;
		width: $width;
		background-color: #fff;
		top: 120px;
		left: 50%;
		margin-left: -$width / 2;
		z-index: 4444;
		@media(min-width:1301px){
			display: block;
		}
	}
	.csw-section__body {
		padding: 100px 0;
		@media(min-width:950px){
			min-height: 716px;
		}
		display: flex;
		flex-direction: column;
		justify-content: center;
		@media(max-width: 909px){
			padding: 0;
		}
	}
	.csw-section__row {
		position: relative;
		// height: 86px;
		height: 110px;
		@media(max-width:909px){
			height: auto;
		}
		@media(max-width:420px){
			
		}
		@media(min-width:910px){
			transition: height 0.5s;
			&.collapsed{
				height: 30px;
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					height: 86px;
				}
			}
		}

		svg{
				display: none;
				position:absolute;
				width: 300px;
				height: 500px;
				.animated{
					fill: none;
					stroke: #000;
					stroke-width: 1px;
					stroke-dasharray:900;
					stroke-dashoffset:0;
					transition: stroke-dashoffset 4s;
					@media(max-width:1300px){
						transition: stroke-dashoffset 2s;
						&.collapsed{
							transition: stroke-dashoffset 1s;
						}
					}
					@media(max-width:1150px){
						transition: stroke-dashoffset 4s;
						&.collapsed{
							transition: stroke-dashoffset 2s;
						}
					}
					&.collapsed{
						stroke-dashoffset:900;
						visibility: hidden;
						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							visibility: visible;
							stroke-dashoffset:0;
						}
					}
					&.uncollapsed{
						visibility: visible;
					}
				}
				.dash{
					@extend .animated;
					stroke:#fff;
					stroke-width: 2px;
					stroke-dasharray:5;
				}
			
				&.long{
					display: none;
					top: 50%;
					margin-top: -250px;
					&.path-1{}
					&.path-2{}
					&.path-3{}
					&.path-4{}
					&.path-5{}
					&.path-6{}
					&.left{
						left: 360px;
					}
					&.right{
						right: 360px;
					}
					@media(min-width:1301px){
						display: block;
					}
				}
				&.short{
					width: 800px;
					height: 300px;
					@media(min-width:910px) and (max-width:1300px){
						display: block;
					}
				}
			}
		// outline: solid 1px red;
		@media(max-width: 1300px){
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}
	}
}

// -----------------------------------------------------------------
.csw-section.cs-trust-partner {
	background-image: url("img/bg_cs-trust-partner.jpg");
	@extend %solid_bg;
}

// -----------------------------------------------------------------
.csw-section.cs-feedback {
	text-align: center;
	background-image: url("img/bg_cs-feedback.jpg");
	@extend %solid_bg;
}

// -----------------------------------------------------------------
.csw-section.what-will-you-get {
	background-image: url("img/bg_what-will-you-get.jpg");
	@extend %solid_bg;
	.csw-section__row{
		display:flex;
		flex-direction:row;
		justify-content:space-between;
		flex-wrap: wrap;
	}
}

// -----------------------------------------------------------------
.csw-section.sf-audit-package {
	.csw-section__container{
		
	}
	@media(max-width:560px){
		background-color:#fff!important;
	}
}

.csw-section.tech-audit{
	.csw-section__head{
		margin: 0 auto;
		width: 90%;
		@media(max-width:992px){
			width: 100%;
			margin: 0;
		}
	}
	.csw-section__body{
		margin: 80px 0 25px;
		@media(max-width:992px){
			margin: 30px 0 25px;
		}
	}
	.csw-section__col:first-child{
		@media(max-width:992px){
			margin-bottom: 30px;
		}
	}
}

.csw-section.clients{
	padding: 0 !important;
}

.csw-section.call-to-action{
	.csw-section__container{
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding:0 0 30px 0;
		flex-wrap: wrap;
	}

	.csw-section__col{
		display: inline-block;
		display: flex;
		flex-direction: row;
		align-items: center;
		max-width: 100%;
		margin: 30px 0 0 0;
		&:last-child{
			margin: 30px 30px 0;
		}
	}
}


.csw-section.why-codeswat{

	background-image: url("img/bg-why-codeswat.jpg");
	@extend %solid_bg;

	.csw-section__body{
		display: flex;
		flex-flow: row wrap;
	}

	.csw-section__row{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 50%;
		@media(max-width:1150px){
			width: 100%;
		}
	}

	.csw-section__col{
		width: 50%;
		@media(max-width:700px){
			width: 100%;
			text-align: center;
		}
	}
}

.csw-section.services {
	color: #000;
	overflow: visible;
	position: relative;
	bottom:0;
	margin-top: 16rem;
	@media(max-width: 600px){
		margin-top: 0;
	}
	.csw-section__container{
		margin-top: -250px;
		@media(max-width: 600px){
			margin-top: 0;
		}
	}
	
	background-image: url("img/bg-services.jpg");
	@extend %solid_bg;

	.csw-section__body{
		margin-top: 30px;
		padding: 20px;
		box-shadow: 0 0 22px rgba(0,0,0,.33);
		background-color: #fff;
		overflow: hidden;
		@media(max-width:1300px){
			display: flex;
			flex-direction: row;
			justify-content: center;
		}

		@media(max-width:600px){
			display: block;
		}
	}
	.csw-section__row{
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;
		&:first-child{
			padding-bottom: 20px;
		}
		&:last-child{
			border-top: 2px solid #efefef;
			padding-top: 20px;
		}
		@media(max-width:1300px){
			width: 50%;
			flex-direction: column;
			justify-content: stretch;
			align-items: center;
			padding: 0;
			&:last-child{
				border-top: none;
				border-left: 2px solid #efefef;
				padding-top: 0;
			}
			&:first-child{
				padding-bottom: 0;
			}
		}

		@media(max-width: 600px){
			width: 100%;
			&:last-child{
				border-top: none;
				border-left: none;
			}
		}
	}
}

.csw-section.support-package{

	.csw-section__body{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.csw-section__col{
		padding: 20px 0;
		width: 33.3%;
		@media(max-width:1160px){
			width: 50%;
		}
		@media(max-width:700px){
			width: 100%;
		}
	}
}

.csw-section.benefits{
	color: #fff;

	background-image: url("img/bg_what-will-you-get.jpg");
	@extend %solid_bg;
	
	.csw-section__body{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	

	.csw-section__col{
		width: 50%;
		@media(max-width: 1100px){
			width: 100%;
			@media(max-width: 600px){
				&:last-child{
					display: none;
				}
			}
		}
	}
}


.csw-section.green-blue{
	color: #fff;
	overflow: hidden;
	.csw-section__container{
		padding: 0;
		display: flex;
		flex-direction:row;
		flex-wrap: nowrap;
		@media(max-width: 1300px){
			flex-wrap: wrap;
		}
	}

	.csw-section__col{
		$separatorWidth: 150px;


		&.separator{
			position: absolute;
			$padding: 3.82rem * 2;
			height: 100%;
			width: $separatorWidth;
			background-color: $blue;
			overflow: hidden;
			left: 50%;
			margin-left: -$separatorWidth / 2;
			z-index: 9999;
			&:before{
				content: "";
			    display: block;
			    border-left: $separatorWidth / 2 solid $green;
	  			border-bottom: 600px solid transparent;
			}


			@media(max-width: 1300px){
				display: none;
			}
		}
		&.left, &.right{
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 50%;
			position:relative;
			padding: 65px 0;
			&:before{
				position: absolute;
				top: 0;
				content: '';
				display: block;
				height: 100%;
				width: 1000px;
			}
			&:after{
				position: absolute;
				top: 0;
				content: '';
				display: block;
				height: 100%;
				width: 1000px;
			}
			@media(max-width: 930px){
				width: 100%;
			}
		}

		&.left{
			background-color: $green;
			padding-right: $separatorWidth / 2;
			padding-left: 5px;
			&:before{
				right: 100%;
				background-color: $green;
			}
			@media(max-width: 930px){
				&:after{
					left: 100%;
					background-color: $green;
				}
			}
		}
		&.right{
			background-color: $blue;
			padding-left: $separatorWidth / 2 + 5;
			&:before{
				left: 100%;
				background-color: $blue;
			}
			@media(max-width: 930px){
				padding-left: 5px;
				&:after{
					right: 100%;
					background-color: $blue;
				}
			}
		}
	}
}

.csw-section.advantages{

	.csw-section__body{
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		@media(max-width: 1300px){
			flex-direction: column-reverse;
			align-items: center;
		}
	}

	.csw-section__col{
		width: 50%;
		&:nth-child(1){
			@media(max-width: 1300px){
				width: 100%;
			}
		}
	}
}

.csw-section.testimonials{
	@media(min-width: 900px){
		background: url('img/bl-testimonials-clients-say-about.jpg') top right no-repeat;
		background-size: auto 100%;
	}
	
	&.industry{
		padding-bottom: 19rem;
		@media(max-width: 600px){
			padding-bottom: 0;
		}
	}
	// @media(min-width: 601px){
	// 	.csw-section__container{
	// 		padding-bottom: 22rem;
	// 	}
	// }
	
	.csw-section__head{
		margin: 20px 0 70px;
		position: relative;
		z-index: 101;
	}
}

.csw-section.services-page{
	.csw-section__body{
		display: flex;
		flex-direction: column;
	}

	.csw-section__row{
		display: flex;
		flex-direction: row;
		@media(max-width: 750px){
			flex-direction: column;
		}
	}
}

.csw-section.solution-utilizing{
	background-image: url("img/bg-solution-util.jpg");
	@extend %solid_bg;
	.csw-section_clouds{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		@media(max-width: 888px){
			flex-direction: column;
		}
	}

	.csw-section__col{
		width: 50%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		@media(max-width: 888px){
			width: 100%;
			&:last-child{
				margin-top: 40px;
			}
		}

		@media(max-width: 450px){
			flex-direction: column;
			align-items: center;
			&:last-child{
				margin-top: 0;
			}
		}
	}
}

.csw-section_packages{
	.csw-section__body{
		margin-top: 60px;
		display: flex;
		flex-direction: row;
		align-items: center;
		@media(max-width: 1230px){
			flex-direction: column;
		}
	}

	.csw-section__row{
		width: 50%;
		display: flex;
		flex-direction: row;
		@media(max-width: 1230px){
			width: 70%;
			&:last-child{margin-top: 60px;}
		}
		@media(max-width: 900px){
			width: 100%;
		}
		@media(max-width: 570px){
			flex-direction: column;
		}
		@media(max-width: 570px){
			&:last-child{margin-top: 0};
		}
	}
}

.csw-section_integration-how-it-works{
	background-image: url("img/bg-section_integr_how-it-works.jpg");
	@extend %solid_bg;
	.csw-section__body{
		display: flex;
		flex-direction: row;

	}
	.csw-section__col{
		
		&:first-child{
			width: 60%;
			padding-right: 30px;
			@media(max-width: 1150px){
				width: 100%;
			}
		}
		&:last-child{
			width: 40%;
			padding-top: 75px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			@media(max-width: 1150px){
				display: none;
			}
		}
	}

	.csw-section__row{
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;
	}
}

.csw-section_integr-benefits{
	.csw-section__body{
		margin-top: 0;
	}
}

.csw-section_industry-descr{
	.csw-section__body{
		margin:0;
	}
}

.csw-section_history{
	.csw-section__container{
		text-align: center;
	}
}

.csw-section_about-grid{
	.csw-section__container{
		padding: 0;
	}
	.csw-section__body{
		margin: 0;
	}
}

.csw-section_lightning-descr{
	.csw-section__head{
		width: 70%;
		margin: 0 auto 40px;
		margin: 0 auto 4rem;
		@media(max-width: 800px){
			width: 90%;
		}
	}
	.csw-section__body{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.csw-section_light-ready{
	background-image: url("img/bg-section_light-ready.jpg");
	@extend %solid_bg;
	.csw-section__body{
		display: flex;
		flex-direction: row;
	}
	.csw-section__col{
		width: 50%;
		@media(max-width: 1050px){
			width: 100%;
		}
		&:last-child{
			margin-top: -60px;
			@media(max-width: 1050px){
				display: none;
			}
		}
	}
}

.csw-section_appexchange{
	overflow: hidden;
	.csw-section__body{
		margin: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.csw-section_great-cloud-solution{
	background-image: url("img/bg-section_cloud-solution.jpg");
	@extend %solid_bg;
}

.csw-section_implementation-grid{
	.csw-section__body{
		margin: 0;
	}
}



.csw-section_results{
	background-image: url("img/bg-section_result.jpg");
	@extend %solid_bg;
	.csw-section__container{
		display: flex;
		flex-direction: row;
	}
	.csw-section__col{
		padding: 0 20px;
		width: 50%;
	}
	@media(max-width: 1024px){
		.csw-section__col{
			width: 100%;
		}
		.csw-section__container{
			flex-direction: column;
		}
	}
}

.csw-section_company-descr{
	.csw-section__container{
		display: flex;
		flex-direction: row;
	}
	.csw-section__col{
		padding: 0 20px;
		display: flex;
		align-items: center;
	}

	@media(max-width: 650px){
		.csw-section__container{
			flex-direction: column;
		}
		.csw-section__col{
			padding: 20px 0;
			justify-content: center;
		}
	}
}

.csw-section_screenshots{
	position: relative;
	z-index: 100;
	&:after{
		position: absolute;
		content: "";
		display: block;
		width: 100%;
		height: 50%;
		top: 25%;
		background-color: $blue;
		background-image:url(./img/bg-section_screens-cover.jpg);
		@extend %solid_bg;
	}
}

.csw-section_cs-sol-end{
	position: relative;
}

.csw-section_quote{
	.csw-section__container{
		display: flex;
		flex-direction: row;
		@media(max-width: 500px){
			flex-direction: column;
		}
	}
}

.csw-section_company-preview {
    background: url('./img/welk-bckg.jpg');
    background-size: cover;
    color: #fff;
    font-size: 17px;
}
.csw-section_company-preview .csw-section__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 50px 0;
}

.csw-section_company-preview .csw-section__col {
    padding: 0 20px;
}

.csw-section_company-preview .csw-section__col:last-child {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}

.csw-section_company-preview .btn{
	display: block;
	text-align: center;
    background: #9fb91c;
    font-size: 16px;
    padding: 7px 30px;
    border: none;
    color: #fff;
    margin-top: 30px;
    text-transform: uppercase;
    line-height: 25px;
}

.csw-section_company-preview .logo {
    margin-bottom: 40px;
    max-width: none;
}


@media (max-width: 650px) {
	.csw-section_company-preview .csw-section__body {
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	        -ms-flex-direction: column;
	            flex-direction: column;
	}

	.csw-section_company-preview .csw-section__col {
	    padding: 20px 0;
	}

	.csw-section_company-preview .btn{
		margin: 2rem auto 0;
	}
    .csw-section_company-preview .logo {
        margin-bottom: 0;
    }
}

.csw-section_hospitality-intro .csw-section__body{
	font-size: 17px;
	margin-top: 0;
}

.hospitality-intro__columns{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    
}
.hospitality-intro__column {
    width: 32%;
    border: 1px solid rgb(169, 169, 169);
    padding: 40px;
    margin-top: 35px;
}
.hospitality-intro__column h2{
    color: #256eb9;
    font-size: 20px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 20px;
}
.hospitality-intro__column ul {
    list-style-position: inside;
    padding: 0;
    margin: 0;
}

@media (max-width: 992px) {
    .hospitality-intro__column {
        width: 100%;
        padding: 25px;
    }   
}

.csw-section_leverage{
	padding-bottom: 65px;
	padding-bottom: 6.5rem;
}