@import "../../../mixins/style.mixins";

$background-color: rgb(220, 222, 229);

.csw-input {
	font-size: 17px;
	color: rgb(17,17,17);
	border: none;
	background-color: $background-color;
	min-height: 40px;
	padding: 12px;
	opacity: .7;
	&:focus {
		outline: none;
		opacity: .9;
	}
	&.text {}
	&.textarea {
		height: 140px;
		resize: none;
	}
	&.full {
		width: 100%;
	}
}


// ===========================================================
.csw-input_cs-feedback.text + .csw-input {
	margin-top: 60px;
	@media(max-width:801px){
		margin-top: 30px;
	}
}

.csw-input_subscribe-email{
	margin: 0 0 2rem;
	background-color: transparent;
	outline: 1px solid #000 !important;
	font-size: 13px;
	font-size: 1.3 rem;
	&:focus{
		outline: 1px solid $green !important;
	}
}

.csw-input__submit{
	transition: all 0.2s ease-in-out;
	padding: 1.2rem 8rem;
	font-size: 2rem;
	margin: 0 auto;
	&.green{
		color: #fff;
		background: linear-gradient(to left, #9FB91C 50%, #7D9116 50%) repeat scroll right bottom/200% 100% transparent;
		border: 0;
	}

	&:hover{
		background-position: top left;
	}

	&:active{
		transform: scale(0.9);
	}
}

.csw-input_container{
	&.csw-input__submit_how-can-we-help{
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 5rem;
	}
}

.input__container{
	position: relative;
	padding: 0 0 2.5rem 0;
	.input__error{
		line-height: 1;
		font-size: 1.4rem;
		position: absolute;
		bottom: .3rem;
		left: 0;
		color: red;
		opacity: 0;
		transition: all .2s ease-in-out;
	}

	&.error{
		.input__error{
			opacity: 1;
		}
	}
}