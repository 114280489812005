@import "../../../mixins/style.mixins";
h1,h2,h3,h4,h5,h6{
    font-family: "Open Sans" !important;
}

.csw-title,
.csw-title__container,
.csw-title__head,
.csw-title__body,
.csw-title__foot {
    font-weight: normal;
    display: block;
    padding: 0;
    margin: 0;

    &.bold{
        font-weight: bold !important;
    }
}
// --------------------
.csw-title {
    text-transform: uppercase;
    line-height: 1.4;
}
.csw-title__container {}
.csw-title__head {
    font-family: "pt_sansregular";
    font-size: 42px;
    font-size: 4.2rem;
    text-shadow: 1px 1px 3px #264273;
    text-transform: uppercase;
    line-height: 54px;
    @media(max-width: 450px){
        font-size: 35px;
        font-size: 3.5rem;
    }
}
.csw-title__body {
    p {
        margin: 15px 0 0;
        font-size: 30px!important;
        font-size: 3rem!important;
        line-height: 45px!important;
        color: #fff;
        text-transform: none;
        max-width: 900px;
        text-shadow: 1px 1px 3px #264273;
        @media(max-width:670px){
            font-size: 25px!important;
        }
        @media(max-width: 450px){
            font-size: 20px!important;
        }
        &.centered{
            margin: 15px auto 0;
        }
    }
}
// --------------------
.csw-title.first {
    font-size: 46px;
    font-size: 4.6rem;
}
.csw-title.second {
    font-size: 32px;
    font-size: 3.2rem;
}
.csw-title.third {
    font-size: 24px;
    font-size: 2.4rem;
}
.csw-title.fourth {
    font-size: 21px;
    font-size: 2.1rem;
}
.csw-title.fifth {
    font-size: 18px;
    font-size: 1.8rem;
}
.csw-title.sixth {
    font-size: 16px;
    font-size: 1.6rem;
}
.csw-title.normal {
    font-size: 17px;
    font-size: 1rem;
}
// --------------------
.csw-title.centred {
    text-align: center;
}
// ------------------------------------------------------------------------
.csw-title_header {
    display: inline-block;
}


// ------------------------------------------------------------------------
.csw-title_footer {
    margin-bottom: 30px;
}

.csw-title.green {
    color: $green;
}
.csw-title.blue {
    color: $blue;
}

// ------------------------------------------------------------------------
.section.tech-audit .csw-title {
    margin-bottom: 10px
}

.csw-section.clients .csw-title.third{
    text-transform: none;
}



.csw-title_clients {
    .csw-title_header {
        // -------------
    }
    .csw-title_subtitle {
        text-transform: none;
        margin-bottom: 37px;
    }
}

.csw-title_tech-audit{
    margin-bottom: 35px;
}

.csw-title_call-to-action{
    max-width: 100%;
}


.csw-title_find-more{
    @media(max-width: 870px){
        margin-bottom: 45px;
    }
}

// .csw-title_services{
//     text-transform: none;
//     padding: 10px;
//     // margin-right: 20px;
//     text-align: center;
//     background-color: $green;
//     text-shadow: 0px -1px 4px rgba(94, 112, 6, 0.75);
//     color: #fff;
//     @media(max-width:579px){
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         height: 100%;
//     }
// }

.csw-title_why-codeswat{
    font-weight: bold;
    margin-bottom: 30px
}

.csw-title_services{
    @media(max-width: 600px){
        color: #fff;
    }
}

.csw-title_package{
    text-align: center;
    text-shadow: -2px -2px 2px rgba(0,0,0,.2);
}

.csw-title_results{
    margin-bottom: 2rem;
}

