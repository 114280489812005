@import "../../../mixins/style.mixins";


.csw-txt {
    display: inline-block;
    * {color: inherit;}
	&.centred {
		text-align: center;
	}
	&.large {
		font-size: 21px;
		font-size: 2.1rem;
	}
	&.big {
		font-size: 18px;
		font-size: 1.8px;
	}
	&.bold {
		font-weight: bold;
	}
	&.green {
	    color: $green;
	}
	&.white {
	    color: white;
	}
}


// --------------------------------------------------------------
.csw-txt_cs-trust-partner {
	font-family: 'railway', sans-serif;
	line-height: 50px;
	text-align: left;
	font-size: 44px;
	font-size: 4.4rem;
	border-width: 3px 3px 3px 0px;
	border-style: solid;
	border-color: #fff;
	padding: 5px 3px 3px 0px;
	min-width: 80px;
}


// --------------------------------------------------------------
.csw-txt_sf-audit-package {
	// outline: solid 1px #f00;
	line-height: 60px;
	&.decorated {
		text-align: center;
		border-radius: 50%;
		border: solid 5px $green;
		margin-right: 10px;
		width: 70px;
	}
}

.csw-txt_text-audit{
	@media(max-width:992px){
		text-align: left!important;
		padding: 0 15px;
	}
}

.csw-txt_why-codeswat{
	&.number{
		display: table-cell;
		vertical-align: middle;
		font-size: 47px;
		line-height: 47px;
		font-weight: bold;
		padding-left: 10px;
	}
}

.csw-txt_advantages{
	padding-right: 30px;
	width: 100%;
}

.csw-txt.services{
	text-transform: uppercase;
	@media(min-width: 1300px){
		text-align: center;
	}
	@media(max-width: 740px){
		font-size: 12px;
		font-size: 1.2rem;
		line-height: 1.6
	}
}

.csw-txt.services-page{
	text-transform: uppercase;
	color: #1e2e59;
	font-weight: bold;
	font-size: 22px;
	font-size: 2.2rem;
	margin: 5px 0;
}

.csw-txt_integr-benefits{
	margin-bottom: 28px;
}

.csw-txt_industry-descr{
	margin-bottom: 30px;
}

.csw-txt_history{
	@media(min-width: 501px){
		width: 80%;
	}
}

.csw-txt_person-name{
	font-weight: bold;
	font-size: 22px;
	font-size: 2.2rem;
	text-transform: uppercase;
}

.csw-txt_person-position{
	font-size: 14px;
	font-size: 1.4rem;
}

.csw-txt_4ps-model{
	font-size: 32px;
	font-size: 3.2rem;
	color: #fff;
}

.csw-txt_grid-number{
	// color: #e1e3e8;
	font-weight: bold;
	font-family: "Open Sans", sans-serif;
	font-size: 130px;
	font-size: 13rem;
	line-height:1;
	@media(max-width:1700px){
		font-size: 100px;
		font-size: 10rem;
	}
	@media(max-width:1400px){
		font-size: 70px;
		font-size: 7rem;
	}
	@media(max-width:1300px){
		font-size: 130px;
		font-size: 13rem;
	}
	@media(max-width:950px){
		font-size: 80px;
		font-size: 8rem;
	}
	@media(max-width:850px){
		font-size: 100px;
		font-size: 10rem;
	}
	@media(max-width:650px){
		font-size: 70px;
		font-size: 7rem;
	}

	&.csw-txt_grid-number_1{
		color: #e1e3e8;
	}
	&.csw-txt_grid-number_2{
		color: #cfd4dd;
	}
	&.csw-txt_grid-number_3{
		color: #e5e8ef;
	}
	&.csw-txt_grid-number_4{
		color: #ccd6e5;
	}
}

.csw-txt.csw-txt_grid-number + .csw-txt {
	color: #1c233a;
	text-transform: uppercase;
	font-size: 28px;
	font-size: 2.8rem;
	font-weight: bold;
	transform: translate(20%, -60%);
	@media(max-width:1460px){
		font-size: 17px;
		font-size: 1.7rem;
	}
}

.csw-txt_appexchange-item-index{
	line-height: 1;
	font-size: 100px;
	font-size: 10rem;
	font-weight: bold;
	color: #9fb8d7;
	margin-right: 20px;
}

.csw-txt_subscribe{
	color: $blue;
	margin-top: 8px;
	margin-top: .8rem;
	font-size: 13px;
	font-size: 1.3rem;
}

.csw-txt_author-name, .csw-txt_author-position{
	width: 100%;
}

.csw-txt_quote{
	margin-bottom: 2rem;
	font-style: italic;
}

.csw-txt_point{
	color: #7c868d;
	margin-bottom: .65rem;
}