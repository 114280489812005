@import "../../../mixins/style.mixins";

.csw-grid{
	display: flex;
	flex-wrap: wrap;
	.single{
		display: relative;
		width: 33.3%;
		&:after{
			content: '';
			display: block;
			padding-bottom: 100%;
			// outline: 1px solid red;
		}
	}

	.double{
		display: relative;
		width: 66.6%;
		&:after{
			content: '';
			display: block;
			padding-bottom: 50%;
			z-index: -1;
			// outline: 1px solid blue;
		}
	}

	.csw-grid__block{
		position: relative;
	}
	.csw-grid__content{
		position: absolute;
		top: 15px;
		bottom: 15px;
		right: 15px;
		left: 15px;
		// outline: 1px solid green;
	}
}

.csw-grid_implementation{
	.csw-grid__foot{
		position: absolute;
		bottom: 20px;
		right: 20px;
		z-index: 1000
	}

	.csw-grid__head{
		margin-bottom: 4rem;
		position: relative;
		z-index: 1000;
	}

	.csw-grid__body{
		position: relative;
		z-index: 1000;
	}

	.csw-grid__content{
		padding: 30px;
	}

	.csw-grid__block-1{
		.csw-grid__content{
			background-color: #e5e5e5;
			background-image: url('img/bg-section_impl-phones.png');
			@extend %solid_bg;
		}
	}
	.csw-grid__block-2{
		.csw-grid__content{
			background-color: $blue;
			color: #fff;
		}
	}
	.csw-grid__block-3{
		.csw-grid__content{
			background-color: $green;
			color: #fff;
		}
	}
	.csw-grid__block-4{
		.csw-grid__content{
			background-color: $green;
			color: #fff;
		}
	}
	.csw-grid__block-5{
		.csw-grid__content{
			background-color: #e5e5e5;
			background-image: url('img/bg-section_impl-laptop.jpg');
			@extend %solid_bg;
		}
	}
	.csw-grid__block-6{
		.csw-grid__content{
			background-color: $blue;
			color: #fff;
		}
	}
	.csw-grid__block-7{
		.csw-grid__content{
			background-color: $blue;
			color: #fff;
		}
	}
	.csw-grid__block-8{
		.csw-grid__content{
			background-color: #dfe1e7;
		}
	}

	@media(max-width: 1200px){
		.single{
			width: 50%;
		}
		.double{
			width: 100%;
		}
		.csw-grid__block-1{
			display: none;
		}
		.csw-grid__block-3, .csw-grid__block-6{
			.csw-grid__content{
				background-color: $green;
			}
		}
		.csw-grid__block-4, .csw-grid__block-7{
			.csw-grid__content{
				background-color: $blue;
			}
		}
	}

	@media(max-width: 800px){
		.single, .double{
			width: 100%;
			&:after{
				display: none;
			}
		}

		.csw-grid__head{
			margin-bottom: 1rem;
			position: relative;
			z-index: 1000;
		}

		.csw-grid__content{
			background-color: transparent !important;
			color: #000!important;
			position: relative;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			padding: 10px 0 60px 0;
		}

		.csw-grid__block:last-child{
			.csw-grid__content{
				padding-bottom: 0;
			}
		}

		.csw-grid__block-5{
			display: none;
		}
	}
}

