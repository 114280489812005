@import "../../../mixins/style.mixins";


.csw-list {
    position: relative;
    &.vertical .csw-list__item {
        display: block;
    }
    &.horisontal .csw-list__item {
        display: inline-block;
    }
    &.double .csw-list__item,
    &.triple .csw-list__item {
        float: left;
    }
    &.double .csw-list__item {
        width: 50%;
        padding-right: 50px;
        @media(max-width: 470px){
            width: 100%;
        }
    }
    &.triple .csw-list__item {
        width: 33.3333333%;
    }
    &.decorated .csw-list__item {
        display: list-item;
        list-style: disc !important;
    }

    &.decorated-green .csw-list__item{
        > *{
            margin-left: 1.5em;
        }
        &:before {
            content: "";
            display: inline-block;
            width: 13px;
            height: 13px;
            background: url('img/list-marker.png');
            background-size: cover;
            position: absolute;
            margin-top: 0.45em;
        }
    }

    &.inside .csw-list__item{
        list-style-position: inside!important;
    }
}
.csw-list__list,
.csw-list__item {
    margin: 0;
    padding: 0;
    list-style: none !important;
    &.outside {
        padding-left: 1em;
        list-style-position: outside;
    }
}

// -----------------------------------------------------------------------
.csw-list_social .csw-list__item + .csw-list__item {
    margin-left: 20px;
}

// -----------------------------------------------------------------------
.csw-list_qlinks .csw-list__item {
    margin-bottom: 10px;
}

// -----------------------------------------------------------------------
.csw-list_cs-trust-partner {
    overflow: hidden;
    .csw-list__list {
        margin: 0 -30px;
    }
    .csw-list__item {
        padding: 35px;
        display: inline-block;
        vertical-align: top;
        width: 50%;
        margin-right: -4px;
        @media(max-width:900px){
            display: block;
            width: 100%
        }
    }
}

// -----------------------------------------------------------------------
.csw-list_industry-experience {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;

    .csw-list__item {
        line-height: 2;
    }

    .csw-list__col{
        padding-right:20px;
    }

    &.half{
        @media(max-width: 1300px){

        }
        @media(max-width: 880px){
            width: 100%;
            .csw-list__col{
                padding-right:30px;
            }
        }
    }

    &.full{
        width: 80%;
        @media(max-width:950px){
            width: 100%;
        }
        .csw-list__col{
            @media(max-width: 730px){
                width: 50%;
            }
            @media(max-width: 570px){
                width: 100%;
            }
        }
    }
}

// -----------------------------------------------------------------------


// -----------------------------------------------------------------------
.csw-list_sf-audit-package {
    // border: solid 5px #f00;
    // overflow:hidden;
    position: relative;
    @media(min-width: 560px){
        &:after{
            width: 100%;
            position: absolute;
            bottom:0;
            content:'';
            height: 100px;
            display: block;
            box-shadow: 0 22px 22px -20px rgba(0,0,0,.33);
        }
        &[data-shadows="2"]{
            &:after{
                box-shadow: 0 22px 22px -20px rgba(0,0,0,.33), 0 22px 22px -22px rgba(0,0,0,.33);
            }
        }
    }
    .csw-list__list,
    .csw-list__item {
        position: relative;
        transition: margin .5s ease-in;
    }
    > .csw-list__list {
        > .csw-list__item {
            $i: 0;
            $z: 3;
            @while $i <= 3 {
                &:nth-child(#{$i}) {
                    z-index: $i;
                }
                $z: $z - 1;
                $i: $i + 1;
            }
            + .csw-list__item {
                margin-top: 60px;
                @media(max-width:560px){
                    margin: 40px 0;
                }
            }
        }
    }
}

.csw-list__clients{
    display: flex;
    flex-direction: row;
    .csw-list__col{
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .csw-list__item{
        padding: 35px;
        min-height: 162px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.csw-list_nav{
    .csw-list__list{
        text-align: right;
        padding-top: 30px;
    }

    .csw-list__item{
        position: relative;
        &:hover .csw-list__list{
            display: block;
            position: absolute;
            background: #fff;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px;
            top:100%;
            left: 0;
            a{
                color: #000;
            }
            &:after{
                content:'';
                display: block;
                height: 10px;
                width: 10px;
                background: #fff;
                position: absolute;
                top: -5px;
                left: 50px;
                transform: rotate(45deg);
            }
        }
        .csw-list__list{
            display: none;
        }
    }
}



.csw-list.benefits{
    padding-right: 30px;
    .csw-list__item{
        margin-top: 30px;
    }
}


.csw-list_integration-how-it-works{
    .csw-list__item{
        margin-bottom: 40px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.csw-list_integr-benefits{
    margin-top: 28px;
}

.csw-item_integr-benefits{
    @media(max-width: 1100px){
        width: 100% !important;
        margin-bottom: 30px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.csw-list__list_case-stydy{
    width: 100%;
    padding: 0 50px;
    @media(max-width:720px){
        padding: 0;
    }
    .csw-list__item{
        width: 50%;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.csw-list_team{
    .csw-list__item{
        display: flex;
        justify-content: space-around;
    }
}

.csw-list_about-grid{
    display: flex;
    flex-wrap: wrap;
}

.csw-list_appexchange-point{
    margin-left: 2rem;
}
.csw-list_case_params{
    display: flex;
    flex-direction: raw;
    flex-wrap: wrap;
    align-items: center;;
    justify-content: space-around;
    > *{
        width: 25%;
    }
    @media(max-width: 1024px){
        > *{
            width: 50%;
            padding: 2rem 0;
        }
    }
    @media(max-width: 600px){
        > *{
            width: 100%;
            padding: 2rem 0;
        }
    }
}

.csw-list_results{
    margin-top: 3rem;
    .csw-list__item{
        margin-bottom: 2rem;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.csw-list__list-leverages{
    .csw-list__item{
        list-style-image: url('svg/ok.svg')!important;
        margin-bottom: 10px;
        margin-bottom: 1rem;
    }
}