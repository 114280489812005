@import "../../../mixins/style.mixins";


.csw-item {}


// ------------------------------------------------------------
.csw-item_footer_posts {
	& + .csw-item {
	    margin-top: 20px;
	}
	& .csw-item__container {
	    text-decoration: none;
	}
	& .csw-item__container:hover .csw-item__head {
	    text-decoration: underline;
	}
	& .csw-item__head {
	    text-transform: uppercase;
	}
	& .csw-item__head,
	& .csw-item__body {
	    display: block;
	}
}


// ------------------------------------------------------------
.csw-item_sf-customization-pattern {
	// outline: solid 1px blue;
	$padding: 90px;
	// outline: solid 1px #ddd;
	position: relative;
	width: 360px;
	float: left;

	@media(min-width:910px){
		.short-path{
		$breakPoint: 1300;
		$breakPoint2: 1100;
		$width: 550px;
		$width2: 350px;
		$left: - $width - 5;
		height: 300px;
		position:absolute;
		left: $left;
		width: $width;
		overflow: hidden;
		top: 50%;
		margin-top: -150px;
		padding-right: 5px;
		@while $breakPoint > $breakPoint2 {
			@media(max-width: $breakPoint + px){
				$width: $width - 10;
				$left:  - $width - 15;
				width: $width + 10;
				left: $left;
			}
			$breakPoint: $breakPoint - 10;
		}

		@while $breakPoint > 910 {
			@media(max-width: $breakPoint + px){
				$width2: $width2 - 10;
				$left:  - $width2 - 15;
				width: $width2 + 10;
				left: $left;
			}
			$breakPoint: $breakPoint - 10;
		}

	}
	}

	@media(max-width:1300px){
		width: 400px;
	}
	@media(max-width:910px){
		width: 100%;
		display: block
	}
	

	.csw-description {

		opacity: 1;
		transition: opacity 0.5s;
		display: table;
		@media(min-width:910px){
			&.collapsed{
				opacity: 0;
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					opacity: 1;
				}	
			}
		}

		.csw-txt{
			@media(min-width:910px) and (max-width:1300px){
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			@media(max-width:910px){
				display: table-cell;
				vertical-align: middle;
				position: relative;
			}
		}
	}
	&.left {
		text-align: right;
		padding-right: $padding;
		@media (max-width:1300px){
			margin:0;
			float: right;
			text-align: left;
			padding-left: $padding;
			padding-right: 5px;
		}
	}
	&.right {
		padding-left: $padding;
		float: right;
	}

	@media (max-width:1300px) {
		margin: 50px 0 0 0 !important;

	}

	&.item_1 {
		margin-top: -30px;
		}
	&.item_2 {
		margin-top: -120px;
		@media (max-width:1300px){
			margin-top:-40px;
			float: right;
		}
	}
	&.item_3 {}
	&.item_4 {
		margin-top: -20px;
	}
	&.item_5 {
		margin-top:80px;
	}
	&.item_6 {}
}


// ------------------------------------------------------------
.csw-item_cs-trust-partner {
	.csw-item__container {}
	.csw-item__row {
		position: relative;
	}
	.csw-item__col {
		min-height: 120px;
		&.left {
			text-align: center;
			position: absolute;
			padding-top: 56px;
			height: 100px;
			width: 80px;
			left: 0;
			top: 0;
			@media (max-width:700px){
	        	display: none;
        	}
		}
		&.right {
			margin-left: 80px;
			padding-left: 20px;
			@media (max-width:700px){
	        	margin-left: 0;
				padding-left: 0;
        	}
		}
	}
	.csw-item__head {
		line-height: 30px;
	}
	.csw-item__body {
		margin-top: 20px;
	}
}


// ------------------------------------------------------------
.csw-item_case-stydy {
	display: flex;
	flex-direction: row;
	justify-content: center;
	max-width: 600px;
	.csw-item__container {
		background-color: $lignt_gray;
		width: 100%;
		height: 250px;
		@media(max-width: 600px){
			height: 400px;
		}
	}
	.csw-item__row {
		position: relative;
		height: 100%;
	}
	.csw-item__col {
		position: absolute;
		width: 45%;
		bottom: 0;
		top: 0;
		&.left {
			left: 0;
			right: 50%;
		}
		&.right {
			left: 50%;
			right: 0;
		}
	}
}


// ------------------------------------------------------------
.csw-item_what-will-you-get {
	width:33.3%;
	padding-right: 30px;
	@media(max-width:1100px){
		width: 100%;
	}
	.csw-item__head {
		// padding-left: 75px;
		padding-top: 20px;
		position: relative;
	}
	.csw-item__body {
		margin-top: 30px;
		@media(max-width:1300px){
			padding-right: 0;
		}
	}
}


// ------------------------------------------------------------
.csw-item_sf-audit-package {
	.csw-item__container {
		box-shadow: 0px 0px 22px rgba(0,0,0,.33);
		position: relative;
		background-color: #fff;
		padding: 60px;
		overflow: hidden;
		@media(max-width:950px){
			padding: 30px 60px;
		}
		@media(max-width:560px){
			box-shadow: none;
			padding: 0;
		}
	}
	.csw-item__head {
		font-size: 32px;
		font-weight: bold;
	}
	.csw-item__body,
	.csw-item__foot {
		margin-top: 30px;
	}

	.csw-item__foot{
		font-weight: bold;
	}
	.csw-item__row {
		position: relative;
	}
	.csw-item__col {
		min-height: 320px;
		@media(max-width:560px){
			min-height: inherit;
		}
		&.left {
			@media(min-width: 951px){
				margin-right: 260px;
			}
		}
		&.right {
			position: absolute;
			width: 260px;
			right: 0;
			top: 0;
		}
	}
}




.csw-item_why-codeswat{
	height: 100%;


	@media(max-width:700px){
		border-bottom: 1px dashed #fff;
	}
	.csw-item__container{
		height: 100%;
		overflow: hidden;
		padding: 30px 0;
	}

	.csw-item__body{
		height: 100%;
		@media(min-width:1151px){
			border-right: 1px dashed #fff;
		}
		padding: 0 30px;
	}

	.csw-item__head{
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		margin-bottom: 30px;
		min-height: 66px;
	}

	&.item_2_2{
		border: none;
		.csw-item__body{
			border: none;
		}
	}
}

.csw-item_services{
	width: 20%;
	padding: 20px;
	border-right: 2px solid #efefef;
	position: relative;
	
	&:last-child{
		border: none;
	}

	@media(max-width:1300px){
		width: 100%;
		border-bottom: 2px solid #efefef;
		display: flex;
		border-right: none;
	}

	.csw-item__container{
		@media(max-width:1300px){
			display: flex;
			justify-content: space-between;
		}
	}


	.csw-item__head{
		
	}

	.csw-item__body{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@media(max-width:1300px){
			flex-direction: row;
		}
	}

	&:after{
		content:"";
		display: none;
		width: 100%;
		height: 5px;
		background-color: $green;
		position: absolute;
		bottom:-20px;
		left: 0;
		@media(max-width: 1300px){
			bottom:0;
		}
	}

	&:hover:after{
		display: block;
	}
}

.csw-item.support-package{
	.csw-item__container{
		display: flex;
		flex-direction: row;
	}

	.csw-item__body{
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 10px;
		flex: 1;
	}

	.csw-item__head{
		padding: 0 10px;
	}
}

.csw-item.benefits{

	$spaceBetweenCols: 20px;
	$spaceBetweenRows: 30px;
	$height:150px;
	$width: $height;

	position: relative;
	margin-left: 30px;
	margin-top: -50px;

	height: $height * 4 + $spaceBetweenRows * 3;
	width: $width;
	@media(min-width: 541px){
		width: $width * 3 + $spaceBetweenCols * 2;
		height: $height * 2 + $spaceBetweenRows;
	}
	@media(min-width: 380px) and (max-width: 540px){
		width: $width * 2 + $spaceBetweenCols;
		height: $height * 2 + $spaceBetweenRows;
	}

	@media(max-width: 1100px){
		margin: 60px auto 0;
	}

	.csw-item__container{
		position: absolute;
		border: 4px solid #a8adbc;
		&:hover{
			border: 4px solid #fff;
			.csw-item__head{
				color: #fff;
			}
		}
		width: $width;
		height: $height;

		&.item_1{}
		&.item_2{top: $height + $spaceBetweenRows;}
		&.item_3{top: $height * 2 + $spaceBetweenRows * 2;}
		&.item_4{top: $height * 3 + $spaceBetweenCols * 4;}

		@media(min-width: 541px){
			&.item_1{top: $height - $spaceBetweenRows}
			&.item_2{top: 0; left: $width + $spaceBetweenCols}
			&.item_3{left: $width + $spaceBetweenCols; top: $height + $spaceBetweenRows;}
			&.item_4{top: $spaceBetweenRows + $spaceBetweenCols; left: ($width + $spaceBetweenCols) * 2}
		}
		

		@media(min-width: 380px) and (max-width: 540px){
			&.item_1{}
			&.item_2{left: $width + $spaceBetweenCols; top: 0;}
			&.item_3{top: $height + $spaceBetweenRows;}
			&.item_4{top: $height + $spaceBetweenRows; left: $width + $spaceBetweenCols;}
		}
	}

	.csw-item__body, .csw-item__head{
		padding-left: 5px;
	}

	.csw-item__head{
		color: #a8adbc;
		font-weight: bold;
		font-size: 77px;
		font-family: Arial;
		line-height: 1;
	}
	.csw-item__body{
		text-transform: uppercase;
		font-size: 14px;
	}
}

.csw-items.testimonials{
	.csw-item__body{
		padding-right: 300px;
		@media(max-width: 900px){
			padding-right: 0;
		}
	}
	.csw-item__foot{
		margin-top: 16px;
		text-transform: uppercase;
	}
}
	
.csw-item_services-page{
	width: 50%;
	@media(max-width:750px){
		width: 100%;
	}
	.csw-item__body{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-around;
		padding: 20px;
		.csw-txt {
			width: 100%;
		}
	}

	&:hover{
		box-shadow: 0 0 22px rgba(0,0,0, .3);
	}
}


.csw-item.sol-util{
	margin: 0 30px;

	&.vf{
		margin: 40px auto 0;
	}

	@media(max-width: 450px){
		margin-bottom: 40px;
		&.item_2_2{
			margin-bottom: 0;
		}
	}
}

.csw-item.package{
	width: 50%;
	background-color: #f8f8f8;
	margin: 0 15px;
	text-align: center;
	position: relative;
	box-shadow: 0 0 5px rgba(0,0,0, .2);
	@media(max-width: 570px){
		width: 100%;
		margin-top: 50px;
		margin-left: 0;
		&.item_1_1{
			margin-top: 0;
		}
	}
	.csw-item__container{
		position: relative;
		padding: 20px 40px 0;
	}
	.csw-item__body{
		padding: 20px 0 40px;
	}
	.csw-item_point{
		position: relative;
		margin: 10px 0 10px;
		padding: 20px 0 10px;
		&:last-child{
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
	.csw-item_separator{
		position: absolute;
		top: 0;
		width: 100%;
		height: 3px;
		background: radial-gradient(rgba(0,0,0,.4), transparent, transparent);
		bacground-size: 100%;
	}
}

.csw-item_integr-benefits{
	padding: 0 30px 0 0 !important;
}

.csw_item_person{
	// width: 100%;
	display: inline-block;
	background-color: #fff;
	padding: 15px;
	.csw-item__foot{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 15px;
		&>*{
			width: 100%;
			text-align: center;
		}
	}
}

.csw-item_about-grid{

		.csw-item__container{
			width: 100%;
		}

		&.csw-item_1{
			background-color: #252c42;
		}

		&.csw-item_2{
			background-image: url("img/bg-grid-2.jpg");
			@media(max-width: 1300px){
				display: none;
			}
		}

		&.csw-item_3{
			background-color: #f3f5fa;
		}

		&.csw-item_4{
			background-image: url("img/bg-grid-8.jpg");
		}

		&.csw-item_5{
			background-image: url("img/bg-grid-5.jpg");
			@media(max-width: 1300px){
				display: none;
			}
		}

		&.csw-item_6{
			background-color: #e5e8ef;
		}

		&.csw-item_7{
			background-image: url("img/bg-grid-7.jpg");
		}

		&.csw-item_8{
			background-image: url("img/bg-grid-12.jpg");
		}

		&.csw-item_9{
			background-color: #ffffff;
		}

		&.csw-item_10{
			background-image: url("img/bg-grid-10.jpg");
		}

		&.csw-item_11{
			background-color: #e9ebf1;
		}

		&.csw-item_13{
			display: none;
			background-image: url("img/bg-grid-14-hidden.jpg");
			@media (min-width: 421px) and (max-width: 1300px){
				display: block;
			}
		}
		
		&.csw-item_14{
			background-image: url("img/bg-grid-14-hidden.jpg");
		}


	&.csw-item_photo{
		@extend %solid_bg;
		@media(max-width: 420px){
			display: none;
		}
	}
	&.csw-item_txt{
		> .csw-item{
			padding: 0 40px;
			color: #000;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;	
		}
	}

	&.single-container, &.double-container{
		position:relative;
		> .csw-item{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@media(max-width: 420px){
				position: relative;
				padding: 40px;
			}
		}
	}
	&.single-container{
		width: 20%;
		@media(max-width: 1300px){
			width: 33.3%;
		}
		@media(max-width: 800px){
			width: 50%;
		}
		@media(max-width: 420px){
			width: 100%;
		}
		&:after{
			content:'';
			display: block;
			padding-bottom: 100%;
			@media(max-width: 420px){
				display: none;
			}
		}
	}

	&.double-container{
		width: 40%;
		@media(max-width: 1300px){
			width: 66.6%;
		}
		@media(max-width: 800px){
			display: none;
		}
		&:after{
			content:'';
			display: block;
			padding-bottom: 50%;
		}
	}

	.csw-item__body{
		margin-top: 1.7rem;
		width: 100%;
		@media(max-width:1460px){
			font-size: 14px;
			font-size: 1.4rem;
		}
	}
}

.csw-item_lightning-descr{
	width: 50%;
	padding: 0 30px 30px;
	&:last-child{
		padding: 0 30px;
	}
	@media(max-width: 800px){
		width: 100%;
		padding: 0 20px 20px;
		&:last-child{
			padding: 0 20px;
		}
	}

	@media(max-width: 550px){
		width: 100%;
		padding: 0 0 20px;
		&:last-child{
			padding: 0;
		}
	}
}


.csw-item_light-ready{
	margin-top: 50px;
	margin-top: 5rem;
}

.csw-item_appexchange{
	width: 50%;
	padding: 20px 65px;
	position: relative;

	.csw-item__container{
		position: relative;
		z-index: 999;
	}
	
	.csw-item__head{
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 20px;
		margin-bottom: 2rem;
	}

	@media(max-width: 1100px){
		width: 100%;
		padding: 20px;
	}

	@media(max-width: 550px){
		width: 100%;
		padding: 20px 0;
	}
}


.csw-items__container-cloud-sol{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	.csw-item{
		padding: 30px 30px 0;
		width: 33.3%;
		@media(max-width: 450px){
			padding: 30px 0 0;
		}
		@media(max-width: 800px){
			width: 100%;
			.csw-item__body{
				max-width: 80%;
				text-align: center;
				margin: auto;
			}
		}
		@media(max-width: 450px){
			width: 100%;
			.csw-item__body{
				max-width: 100%;
				text-align: center;
				margin: auto;
			}
		}
	}
}

.csw-item_case_params{
	padding: 0 1rem;
	.csw-item__container{
		display: flex;
		flex-direction: raw;
		align-items: center;

		@media(max-width: 1024px){
			// justify-content: center;
			padding: 0 0 0 6rem;
    	}
    	@media(max-width: 600px){
    		max-width: 250px;
    		margin: 0 auto;
    		// padding: 0 9rem;
    		// display: inline-block;
	        // justify-content: center;
    	}
	}

	.csw-item__body{
		padding-left: 2rem;
	}
}

.csw-item_leverage{
	padding: 2rem 0;

	&.silver{
		background-color: #f8f9fb;
	}

	.csw-item__body {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.csw-item__image {
		@media(max-width: 768px){
			display: none;
		}
		padding: 0 6rem;
		img{
			max-width: 350px;
			max-width: 35rem;
		}
	}

	.csw-item__description-title{
		color: #256eb9;
		font-size: 21px;
		font-size: 2.1rem;
		margin-bottom: 30px;
		margin-bottom: 3rem;
	}
}




