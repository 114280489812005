@import "../../../mixins/style.mixins";


.csw-form,
.csw-form__container,
.csw-form__head,
.csw-form__body,
.csw-form__foot {}

// ----------------------------------------------------------------------------
.csw-form {}
.csw-form__container {}
.csw-form__head {}
.csw-form__body {}
.csw-form__foot {}

// ----------------------------------------------------------------------------
.csw-form_cs-feedback {
	display: inline-block;
	.csw-form__head {}
	.csw-form__row {}
	.csw-form__col {
		float: left;
		width: 50%;
		padding: 30px;
		@media(max-width:801px){
			width: 100%;
			&:first-child{
				padding-bottom: 0;
			}
		}
	}
}










