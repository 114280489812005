@import "../../../mixins/style.mixins";


.csw-image {
	&.centred{
		display: block;
		margin: 0 auto;
	}
}


// ---------------------------------------------------------------
.csw-image_sf-customization-pattern {
	display: block;
	position: absolute;
	margin-left: -161px;
	left: 50%;
	top: -70px;
	// -----------------------
	$i: 0;
	$z: 6;
	@while $i <= 6 {
		&.csw-image_#{$i} {
			z-index: 9999 + $z * 2;
		}
		$z: $z - 1;
		$i: $i + 1;
	}
	@media(max-width:1300px){
		margin:0;
		left: 0;
	}

	@media(max-width:910px){
		display: none;
	}
}

// ---------------------------------------------------------------
.csw-image_sf-audit-package {
	position: absolute;
	right: 0;
	top: 0;
	@media(max-width:950px){
		display: none;
	}
}

.csw-image_case-studies{
	margin: 0 auto;
}

.csw-image.services{
	width: 50px;
	height: 50px;
	margin: 0 auto 20px;
	display: block;
	@media(max-width:580px){
		width: 100px;
	}

	@media(max-width:1300px){
		margin: 0 10%;
		width: 40px;
		height: 40px;
	}

	@media(max-width:770px){
		margin: 0 15%;
	}
}

.csw-img_why-codeswat{
	display: table-cell;
	vertical-align: middle;
	padding-right: 10px;
}

.csw-img_support-package{
	width:90px;
	height: 90px;
}

.csw-image_advantages-devices{
	margin: -65px 0 0 90px;
	@media(max-width: 1300px){
		width: 100%;
		margin: 50px 0;
	}
}

.csw-image_testimonial-quotes{
	position: absolute;
	top:50px;
	left: 0;
	user-drag: none;
	z-index: 100;
}

.csw-image.services-page{
	width: 65px;
	height: 65px;
}

.csw-image_integration-how-it-works{
	$width: 200px;
	$height: 200px;
	width: $width;
	height: $height;
	margin-left: $width / 2 - 70;
	margin-top: -$height / 2 - 10;
	opacity: .7;
	transition: opacity .3s;
	&:hover{
		opacity: 1;
	}

}

.csw-image_person-photo{
	max-width: 100%;
}


.csw-image_subscribe-plane{
	display: block;
	width: 30%;
	margin: 2rem auto;
}

.csw-image_client{
	max-width: 100%;
}

.csw-image_cs-screen{
	max-width: 100%;
	display:block;
	margin: 0 auto;
}

.csw-image_cs-screens{
	max-width: 100%;
	display:block;
	margin: 0 auto;
}

.csw-image_cs-quotes{
	// max-width: 100%;
	padding: 10px 30px 0;
	@media(max-width: 500px){
		padding: 0 0 10px;
	}
}