@import "../../../mixins/style.mixins";

.csw-telescope__item {
	transition: top .3s !important;
	top: 0;
	margin: 22px;
	&:last-of-type{
		margin-bottom: 0;
		.csw-item__container {

		}
	}

	@media(max-width:560px){
		margin: 0;
	}
}

.telescope__list-container{
	overflow: hidden;
}









