@import "../../../mixins/style.mixins";


.csw-icon {
    display: inline-block;
    &.fontello{
    	font-family: fontello;
    }
}


// -----------------------------------------------------
.csw-icon_footer {
    color: #888;
    font-size: 36px;
    line-height: 60px;
	&:hover {
	    color: inherit;
	    text-decoration: none;
	}
}


// -----------------------------------------------------
.csw-icon_sf-customization-pattern {
	// outline: solid 1px #f00;
	font-size: 60px;
	height: 60px;
	position: absolute;
	margin-top: -30px;
	top: 50%;
	&.left {
		right: 0;
		@media(max-width:1300px){
			left:0;
		}
	}
	&.right {
		left: 0;
	}
}


// -----------------------------------------------------
.csw-icon_what-will-you-get {
	font-size: 70px;
	position: absolute;
	left: 0;
	top: 0;
}

.csw-icon_cloud-sol{
	font-family: 'fontello';
	font-size: 66px;
	display: block;
	text-align: center;
	opacity: .7;
}

.csw-icon_implementation-number{
	color: #fff;
	opacity: .3;
	position: absolute;
	z-index: 999;
	user-select: none;
}