@import "../../mixins/style.mixins";


.wrapper {
	// outline: solid 1px #00f;
    min-height: 10px;
    max-width: $breackpoint;
    margin: 0 auto;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    @extend %clearfix;
    @media screen and (max-width: $breackpoint + 21px) {
        width: 100%;
    }
    @media screen and (max-width: 960px) {
        padding: 0 20px;
    }
    @media screen and (max-width: 1300px) {
        padding: 0 30px;
    }
}

.wrapper_about-grid{
    padding: 0;
}











