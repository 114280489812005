$linkActive: #9fb91c;
$linkInactive: #428bca;
.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all .2s ease-in-out;
    z-index: 999999;
    .menu,
    .sub-menu {
        list-style: none;
        margin: 0;
    }
    .menu-item {
        position: relative;
        a {
            font-size: 13px;
            padding: 1rem 1.5rem;
            display: block;
        }
        &:hover {
            > a {
                color: $linkActive !important;
            }
            .open-child:after{
                color: $linkActive !important;
            }
        }
        &.current-menu-item,
        &.current-menu-parent {
            >a {
                color: $linkActive !important;
            }
            >.open-child:after {
                color: $linkActive !important;
            }
        }
    }

    .open-child {
        transition: all .2s ease-in-out;
        font-family: FontAwesome;
        display: none;
        position: absolute;
        z-index: 2;
        cursor: pointer;
        height: 44px;
        width: 44px;
        right: 0;
        top: 0;
        &:after {
            color: $linkInactive;
            content: '\f107';
            display: block;
            font-family: FontAwesome;
            font-size: 25px;
            line-height: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .menu-item-has-children {
        &.opened {
            >.open-child {
                transform: rotate(180deg);
            }
        }
        >.open-child {
            display: block;
        }
    }

    // --------------------------------------
    // FULL MENU STYLES
    // --------------------------------------
    .nav_full {
        padding: 0 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .menu {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            >.menu-item {
                > a {
                    color: #fff;
                    font-size: 15px;
                }
            }
        }

        .open-child:after{
            font-size: 16px;
            color: #fff
        }

        .menu-item {
            &:hover {
                .sub-menu {
                    display: block;
                }
                .open-child{
                    transform: rotate(180deg);
                }
            }
        }

        .menu-item-has-children{
            padding-right: 2.5rem;
        }

        .nav_logo {
            height: 120px;
            padding: 1rem 0;
            transition: all .2s ease-in-out;
        }
        @media(max-width: 900px) {
            display: none;
        }
        .sub-menu {
            display: none;
            box-shadow: 0 0 22px rgba(0, 0, 0, .3);
            display: none;
            position: absolute;
            top: 100%;
            left: 50%;
            background-color: #fff;
            transform: translateX(-50%);
            padding: 0.5rem 0;
            min-width: 200px;
            a {
                display: block;
                color: $linkInactive;
            }
        }
    }

    &.scrolled{
        .nav_full {
        background-color: #fff;
        box-shadow: 0 0 11px rgba(0, 0, 0, .2);
            .nav_logo {
                height: 70px;
                padding: 1rem 0;
            }

            .open-child:after{
                font-size: 16px;
                color: $linkInactive;
            }

            .menu{
                >.menu-item {
                    > a {
                        font-size: 15px;
                        color: $linkInactive;
                    }
                }
            }
        }
    }
    // --------------------------------------
    // MOBILE MENU STYLES
    // --------------------------------------
    .nav_mobile {
        display: none;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        overflow: auto;
        @media(max-width: 900px) {
            display: block;
        }

        .menu{
            padding: 0 3rem;
            > .menu-item {
                > a {
                    text-transform: uppercase;
                }
            }
        }

        .nav_btn {
            transition: all .2s ease-in-out;
            padding: 2rem;
            cursor: pointer;
            .nav_btn-line {
                transition: all .2s ease-in-out;
                border-radius: 3px;
                width: 35px;
                height: 3px;
                background-color: #fff;
                &.two {
                    margin: 7px 0;
                }
            }
        }

        .sub-menu {
            padding: 0 0 0 4rem;
            height: 0;
            overflow: hidden;
        }

        .nav__mobile-head {
            position: relative;
            z-index: 999999;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            transition: all .2s ease-in-out;
            padding: 0 1rem;
        }

        .menu-menu-container {
            display: none;
            overflow: auto;
        }

        .nav_logo {
            height: 70px;
            padding: 1rem 0;
            position: relative;
            z-index: 999999;
        }
        .sub-menu {
            height: 0;
            overflow: hidden;
        }
    }

    &.opened{
        .nav_mobile {
            height: 100%;
            background-color: #fff;
            .nav_logo {
                visibility: hidden;
            }

            .nav__mobile-head {
                box-shadow: none;
            }

            .menu-item{
                &.opened{
                    .sub-menu {
                        height: auto;
                    }
                }
            }

            .nav_btn {
                .nav_btn-line {
                    background-color: $linkInactive;
                }
                .one {
                    width: 25px;
                    transform: translateY(10px) rotate(45deg);
                }
                .two {
                    opacity: 0;
                }
                .three {
                    width: 25px;
                    transform: translateY(-10px) rotate(-45deg);
                }
            }

            .menu-menu-container {
                display: block;
            }
        }
    }

    &.scrolled{
        .nav_mobile{
            box-shadow: 0 0 11px rgba(0, 0, 0, .2);
            .nav__mobile-head {
                background-color: #fff;
                .nav_btn {
                    .nav_btn-line {
                        background-color: #2d375c;
                    }
                }
            }
        }
    }
}
