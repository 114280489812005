@import "../../../mixins/style.mixins";


// .csw-paragraph,
// .csw-paragraph__container,
// .csw-paragraph__head,
// .csw-paragraph__body,
// .csw-paragraph__foot {}
// ------------------------------------------------------------------------
.csw-paragraph {
	margin: 0;
	padding: 0;
	font-size: 17px;
	font-family: "Open Sans";
	color: rgb(0, 0, 0);
	line-height: 1.588;
}




