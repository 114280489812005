@import "../../../mixins/style.mixins";


.csw-btn {
	line-height: 20px;
	padding: 15px 20px;
	text-transform: uppercase;
	border: none;
    outline: 0;
	&.green {
		color: #fff;
		background-color: $green;
	}
	&.blue {
		color: #fff;
		background-color: $blue;
		&:hover{
			background-color: darken($blue, 10%);
		}
	}
	&.full {
		width: 100%;
	}
}

.csw-btn_nav {
    min-width: 120px;
    margin-top: 9px;
}

.csw-btn_call-to-action {
	font-size: 17px!important;
	padding: 10px 20px;
	line-height: 33.6px;
    white-space: nowrap;
    &:hover{
    	background-color: darken($blue, 5%);
    }
}

.csw-btn_cs-feedback {
	min-width: 260px;
	&:hover {
		background-color: darken($green, 5%);
	}
}

.csw-btn.our-commitment{
	margin-top: 20px;
}

.csw-btn_request-package{
	
	width: 100%;
	&:hover{
		background-color:darken($blue, 10%);
	}
}

.csw-btn_subscribe{
	font-size: 13px;
	font-size: 1.3rem;
}